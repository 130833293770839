import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { LocationSearch } from '~components/forms/location';

const WishAddress = ({ defaultAddress, isDataLoading, onSubmit = () => {} }) => {
  const intl = useIntl();
  const [address, setAddress] = useState(defaultAddress);

  useEffect(() => {
    if (!address) {
      setAddress(defaultAddress);
    }
  }, [defaultAddress]);

  const handleAddressChange = async (loc) => {
    if (!loc) {
      return;
    }

    const newLocation = {
      locationId: loc.place_id,
      address: loc.address,
    };

    setAddress(newLocation);
    onSubmit(loc);
  };

  return (
    <div className="c-form-section c-form-section--center c-form-section--address">
      <div className="c-form-section__header">
        {intl.formatMessage({
          id: 'containers.wishes.form.fields.address.headline',
          defaultMessage: 'Your cleaning location',
        })}
      </div>
      <div className="c-form-section__content">
        <div className="c-fieldset">
          <LocationSearch
            isDataLoading={isDataLoading}
            location={address}
            placeholder={intl.formatMessage({
              id: 'containers.wishes.form.fields.address.placeholder',
              defaultMessage: 'Address',
            })}
            name="address"
            className="c-autocomplete--focus-disabled"
            onSubmit={(loc) => handleAddressChange(loc)}
          />
        </div>
      </div>
    </div>
  );
};

export default WishAddress;
