import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';

import { getSelectableLanguages } from '~helpers/common';
import { MAX_NOTE_LENGTH } from '~src/constants';

import Form from '~components/forms/form';
import Radio from '~components/forms/radio';
import Button from '~components/button';
import { LanguageSelectorControlled } from '~components/forms/language-selector';
import { NumericInputControlled } from '~components/forms/numeric-input';
import { AvailabilityControlled } from '~components/forms/availability';
import { CheckboxControlled } from '~components/forms/checkbox';
import { TextareaControlled } from '~components/forms/textarea';
import CharacterCount from '~components/forms/character-count';

import '../style.scss';

const HOUR = 3600;
const MAX_HOURS_PER_APPOINTMENT = 8;

const WishFields = ({
  wish,
  translationsContext,
  pricingDetails,
  defaultPrice,
  onSubmit = () => {},
  isSubmitting = false,
}) => {
  const intl = useIntl();

  const { duration, interval, timeframes, languages, attributes, personalNote } = wish;
  const { minPrice, maxPrice, currency } = pricingDetails;
  const step = maxPrice > 10000 ? 1 : 0.25;

  const selectableLanguages = getSelectableLanguages();

  const validationSchema = Yup.object().shape({
    duration: Yup.mixed().required(),
    startingDate: Yup.mixed().required(),
    interval: Yup.mixed().required(),
    timeframes: Yup.mixed().test('at-least-one', 'components.form.validation.availability.required', function (v) {
      return !v || v.length === 0 ? false : v.filter((d) => d.length > 0).length > 0;
    }),
    languages: Yup.mixed().test('at-least-one', 'components.form.validation.language.required', function (v) {
      return v && v.length > 0;
    }),
    attributes: Yup.object().shape({ dog: Yup.boolean(), cat: Yup.boolean(), ironing: Yup.boolean() }),
    maximumRate: Yup.number()
      .min(minPrice / 100)
      .max(maxPrice / 100),
    personalNote: Yup.string().max(MAX_NOTE_LENGTH).noEmailInContent().noPhoneInContent(),
  });

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      duration,
      interval,
      timeframes,
      languages,
      attributes: {
        cat: attributes?.indexOf('cat') >= 0,
        dog: attributes?.indexOf('dog') >= 0,
        ironing: attributes?.indexOf('ironing') >= 0,
      },
      maximumRate: defaultPrice / 100,
      personalNote: personalNote || '',
    },
  });

  const watchNote = methods.watch('personalNote');

  useEffect(() => {
    const selectedRate = methods.getValues('maximumRate');
    if (selectedRate * 100 > maxPrice) {
      methods.setValue('maximumRate', maxPrice / 100);
      return;
    }

    if (selectedRate * 100 < minPrice) {
      methods.setValue('maximumRate', minPrice / 100);
    }
  }, [currency]);

  const handleFormSubmit = () => {
    if (isEmpty(methods.errors) || !isSubmitting) {
      const formData = methods.getValues();

      const w = {
        ...wish,
        duration: formData.duration,
        interval: formData.interval,
        timeframes: formData.timeframes,
        languages: formData.languages,
        maximumRate: { amount: formData.maximumRate * 100, currency },
        attributes: Object.keys(formData.attributes).filter((b) => formData.attributes[b]),
        personalNote: formData.personalNote,
      };
  
      onSubmit(w);
    }
  };

  return (
    <Form methods={methods} validationSchema={validationSchema} onSubmit={handleFormSubmit} className="c-form__wish">
      {/* HOURS PER APPOINTMENT */}
      <div className="c-form-section c-form-section--center c-form-section--duration">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.wishes.form.fields.duration.headline',
            defaultMessage: 'Hours per appointment',
          })}
        </div>
        <div className="c-form-section__content">
          <div className="c-fieldset" data-cy="duration">
            <NumericInputControlled
              min={1 * HOUR}
              max={MAX_HOURS_PER_APPOINTMENT * HOUR}
              step={0.5 * HOUR}
              name="duration"
              placeholder={intl.formatMessage({
                id: 'containers.wishes.form.fields.duration.placeholder',
                defaultMessage: 'Number of hours',
              })}
              canFocus={false}
              className="c-create-wish__duration"
              format={(d) =>
                intl.formatMessage(
                  {
                    id: 'containers.wishes.form.fields.duration.value',
                    defaultMessage: '{number, plural, =1 {# hour} other { # hours}} per appointment}',
                  },
                  { number: d / HOUR }
                )}
            />
          </div>
        </div>
      </div>

      {/* FREQUENCY */}
      <div className="c-form-section c-form-section--center">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.wishes.form.fields.interval.headline',
            defaultMessage: 'Frequency',
          })}
        </div>
        <div className="c-form-section__content">
          <div className="c-fieldset" data-cy="interval">
            <div className="c-form__input">
              <Radio.Controlled name="interval" className="c-radio__container--horizontal">
                <Radio.Option value={1} className="c-radio--bordered">
                  {intl.formatMessage({
                    id: 'containers.wishes.form.fields.interval.values.weekly',
                    defaultMessage: 'Weekly',
                  })}
                </Radio.Option>
                <Radio.Option value={2} className="c-radio--bordered">
                  {intl.formatMessage({
                    id: 'containers.wishes.form.fields.interval.values.every-2-weeks',
                    defaultMessage: 'Every 2 weeks',
                  })}
                </Radio.Option>
                <Radio.Option value={3} className="c-radio--bordered">
                  {intl.formatMessage({
                    id: 'containers.wishes.form.fields.interval.values.every-3-weeks',
                    defaultMessage: 'Every 3 weeks',
                  })}
                </Radio.Option>
                <Radio.Option value={4} className="c-radio--bordered">
                  {intl.formatMessage({
                    id: 'containers.wishes.form.fields.interval.values.every-4-weeks',
                    defaultMessage: 'Every 4 weeks',
                  })}
                </Radio.Option>
              </Radio.Controlled>
            </div>
          </div>
        </div>
      </div>

      {/* MAXIMUM RATE */}
      <div className="c-form-section c-form-section--center" data-cy="qa-maximum-rate">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.wishes.form.fields.maximum-rate.headline',
            defaultMessage: 'Maximum rate',
          })}
        </div>
        <div className="c-form-section__content">
          <div className="c-fieldset">
            <NumericInputControlled
              min={minPrice / 100}
              max={maxPrice / 100}
              step={step}
              name="maximumRate"
              placeholder={intl.formatMessage({
                id: 'containers.wishes.form.fields.maximum-rate.placeholder',
                defaultMessage: 'Maximum rate',
              })}
              format={(d) =>
                intl.formatNumber(d, {
                  style: 'currency',
                  currency,
                })
              }
            />
          </div>
        </div>
      </div>

      {/* AVAILABILITY */}
      <div className="c-form-section c-form-section--center">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.wishes.form.fields.availability.headline',
            defaultMessage: 'Days of week',
          })}
        </div>
        <div className="c-form-section__content">
          <div className="c-fieldset">
            <AvailabilityControlled name="timeframes" />
          </div>
        </div>
      </div>

      {/* LANGUAGES SECTION */}
      <div className="c-form-section c-form-section--center c-form-section--languages">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.wishes.form.fields.languages.headline',
            defaultMessage: 'Languages',
          })}
        </div>
        <div className="c-form-section__content">
          <div className="c-fieldset" data-cy="languages">
            <LanguageSelectorControlled
              languages={selectableLanguages}
              defaultValues={methods.getValues('languages')}
              name="languages"
            />
          </div>
        </div>
      </div>

      {/* ATTRIBUTES */}
      <div className="c-form-section c-form-section--center">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.wishes.form.fields.attributes.headline',
            defaultMessage: 'Last question',
          })}
        </div>
        <div className="c-fieldset" data-cy="blockers">
          <div className="c-form__input">
            <div className="c-checkbox__container--horizontal">
              <CheckboxControlled
                name="attributes.dog"
                defaultChecked={attributes?.indexOf('dog') >= 0}
                label={intl.formatMessage({
                  id: 'containers.wishes.form.fields.attributes.values.dog',
                  defaultMessage: 'I have a dog',
                })}
                className="l-bordered"
              />
              <CheckboxControlled
                name="attributes.cat"
                defaultChecked={attributes?.indexOf('cat') >= 0}
                label={intl.formatMessage({
                  id: 'containers.wishes.form.fields.attributes.values.cat',
                  defaultMessage: 'I have a cat',
                })}
                className="l-bordered"
              />
              <CheckboxControlled
                name="attributes.ironing"
                defaultChecked={attributes?.indexOf('ironing') >= 0}
                label={intl.formatMessage({
                  id: 'containers.wishes.form.fields.attributes.values.ironing',
                  defaultMessage: 'I want cleaner to iron',
                })}
                className="l-bordered"
              />
            </div>
          </div>
        </div>
      </div>

      {/* PERSONAL NOTE */}
      <div className="c-form-section c-form-section--center c-form-section--vertical c-form-section--pre-last">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.wishes.form.fields.personal-note.headline',
            defaultMessage: 'Something about yourself',
          })}
        </div>
        <div className="c-personal-note">
          <CharacterCount
            field={watchNote}
            max={MAX_NOTE_LENGTH}
            className="c-fieldset__info c-fieldset__info--right"
          />
          <div className="c-fieldset" data-cy="personal-note">
            <TextareaControlled
              name="personalNote"
              className="c-personal-data__note-textarea"
              placeholder={intl.formatMessage({
                id: 'containers.wishes.form.fields.personal-note.placeholder',
                defaultMessage: 'Tell us something about yourself',
              })}
              isAutoExpand
            />
          </div>
        </div>
      </div>

      <div className="c-form-section c-form-section--center c-form-section--last">
        <Button
          name="submit"
          type="submit"
          className="c-btn"
          disabled={!isEmpty(methods.errors) || isSubmitting}
          isLoading={isSubmitting}
        >
          {intl.formatMessage({
            id: `containers.wishes.${translationsContext ? `${translationsContext}.` : ''}next-step`,
            defaultMessage: 'Next step',
          })}
        </Button>
      </div>
    </Form>
  );
};

export default WishFields;
